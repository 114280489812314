import React, { useEffect, useState } from 'react';
import { logFirebaseData } from '../utils/dataLogger';

export default function Debug() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await logFirebaseData();
      } catch (error) {
        console.error('Error in Debug component:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div style={{ padding: '20px' }}>
        <h1>Loading Firebase Data Structure...</h1>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <h1>Firebase Data Structure</h1>
      <p>Check the browser console (F12) to see the data structure.</p>
    </div>
  );
} 