import { db, auth } from '../firebase/config';
import { 
  collection, 
  getDocs, 
  doc, 
  getDoc 
} from 'firebase/firestore';

const logWithIndent = (message, level = 0) => {
  const indent = '  '.repeat(level);
  console.log(`${indent}${message}`);
};

const formatValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value, null, 2);
  }
  if (typeof value === 'number') {
    return Number(value.toFixed(6)); // Format numbers to 6 decimal places
  }
  return value;
};

const logObject = (obj, level = 0) => {
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && value !== null) {
      logWithIndent(`${key}:`, level);
      logObject(value, level + 1);
    } else {
      logWithIndent(`${key}: ${formatValue(value)}`, level);
    }
  }
};

const logRunDetails = (data, level = 0) => {
  // Log Training Information
  if (data.tuning_Info) {
    logWithIndent('🎯 Training Configuration:', level);
    logWithIndent(`• Model: ${data.tuning_Info.model}`, level + 1);
    logWithIndent(`• Dataset: ${data.tuning_Info.dataset_name}`, level + 1);
    logWithIndent(`• Status: ${data.tuning_Info.status}`, level + 1);
    logWithIndent(`• Creation Name: ${data.tuning_Info.creation_name}`, level + 1);
    
    logWithIndent('⚙️ Training Parameters:', level + 1);
    logWithIndent(`• Learning Rate: ${data.tuning_Info.learning_rate}`, level + 2);
    logWithIndent(`• Epochs: ${data.tuning_Info.Epochs}`, level + 2);
    logWithIndent(`• Max Sequence Length: ${data.tuning_Info.max_sequence_length}`, level + 2);
    logWithIndent(`• Generation Length: ${data.tuning_Info.generation_length}`, level + 2);
    logWithIndent(`• LoRA Alpha: ${data.tuning_Info.lora_alpha}`, level + 2);
    logWithIndent(`• Rank: ${data.tuning_Info.rank}`, level + 2);
    logWithIndent(`• Weight Decay: ${data.tuning_Info.weight_decay}`, level + 2);
    logWithIndent(`• Evaluation Split: ${data.tuning_Info.evaluation_split}`, level + 2);
    logWithIndent(`• Optimizer: ${data.tuning_Info.optimizer}`, level + 2);
    logWithIndent(`• LR Scheduler: ${data.tuning_Info.learning_rate_scheduler_type}`, level + 2);
    
    logWithIndent('🔗 Links:', level + 1);
    logWithIndent(`• Model: ${data.tuning_Info.finish_link}`, level + 2);
    logWithIndent(`• Inference Script: ${data.tuning_Info.inference_script_url}`, level + 2);
  }

  // Log Evaluation Results
  if (data.STD_Eval) {
    logWithIndent('📊 Standard Evaluation:', level);
    logWithIndent(`• Training Time: ${data.STD_Eval.train_time} seconds`, level + 1);
    logWithIndent(`• Training Loss: ${data.STD_Eval.train_loss}`, level + 1);
    logWithIndent(`• Evaluation Loss: ${data.STD_Eval.eval_loss}`, level + 1);
  }

  // Log AI Evaluation
  if (data.AI_Eval) {
    logWithIndent('🤖 AI Evaluation:', level);
    if (Object.keys(data.AI_Eval.AI_Scores || {}).length > 0) {
      logObject(data.AI_Eval.AI_Scores, level + 1);
    } else {
      logWithIndent('No AI evaluation scores available', level + 1);
    }
  }
};

export const logFirebaseData = async () => {
  try {
    console.log('\n=== 🔥 FIREBASE DATA STRUCTURE LOGGER 🔥 ===\n');
    
    // Get the Runs collection
    const runsRef = collection(db, 'Runs');
    const runsSnapshot = await getDocs(runsRef);
    
    logWithIndent('📁 Runs Collection:', 0);
    console.log(''); // Add spacing
    
    // Iterate through each user/admin document in Runs
    for (const runDoc of runsSnapshot.docs) {
      const runData = runDoc.data();
      logWithIndent(`📄 Document ID: ${runDoc.id}`, 1);
      logWithIndent(`📌 Metadata:`, 2);
      logWithIndent(`• Initialized: ${runData.initialized}`, 3);
      logWithIndent(`• Total Sub-Collections: ${runData.subCollections?.length || 0}`, 3);
      
      if (runData.subCollections && runData.subCollections.length > 0) {
        logWithIndent('📂 Fine-tuning Runs:', 2);
        for (const subCollId of runData.subCollections) {
          logWithIndent(`🔹 Run ID: ${subCollId}`, 3);
          
          // Get the subcollection data
          const subCollRef = collection(doc(db, 'Runs', runDoc.id), subCollId);
          const subCollSnapshot = await getDocs(subCollRef);
          
          // Combine all data for this run
          const runDetails = {};
          subCollSnapshot.docs.forEach(doc => {
            runDetails[doc.id] = doc.data();
          });
          
          // Log the combined run details
          logRunDetails(runDetails, 4);
          console.log(''); // Add spacing between runs
        }
      }
      console.log(''); // Add spacing between documents
    }
    
    // Get the users collection
    const usersRef = collection(db, 'users');
    const usersSnapshot = await getDocs(usersRef);
    
    logWithIndent('👥 Users Collection:', 0);
    console.log(''); // Add spacing
    
    // Iterate through each user document
    for (const userDoc of usersSnapshot.docs) {
      const userData = userDoc.data();
      logWithIndent(`👤 User ID: ${userDoc.id}`, 1);
      logWithIndent('📌 User Data:', 2);
      logWithIndent(`• First Login: ${userData.firstLogin}`, 3);
      logWithIndent(`• Email: ${userData.email || 'N/A'}`, 3);
      logWithIndent(`• Hugging Face Token: ${userData.huggingFaceToken ? '****' : 'N/A'}`, 3);
      logWithIndent(`• Modal Secret: ${userData.modalSecret ? '****' : 'N/A'}`, 3);
      logWithIndent(`• Modal Token: ${userData.modalToken ? '****' : 'N/A'}`, 3);
      logWithIndent(`• Last Updated: ${userData.updatedAt || 'N/A'}`, 3);
      console.log(''); // Add spacing between users
    }
    
    console.log('=== 🔥 END OF DATA STRUCTURE 🔥 ===\n');
    
  } catch (error) {
    console.error('❌ Error logging Firebase data:', error);
  }
}; 