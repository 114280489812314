import { Search, ChevronRight, Play } from 'lucide-react';
import React, { useState } from 'react';
import modalLogo from '../faq-Images/modal logo.jpg';
import openaiLogo from '../faq-Images/openaiLogo.png';

const navItems = [
  { name: <strong>Core Features</strong>, href: "/#features" },
  { name: <strong>Resources</strong>, href: "/resources" },
  { name: <strong>Demo Video</strong>, href: "/#demo-video" }
];

const content = {
  articles: [
    {
      id: 1,
      // Template Article (copy and modify this for new articles)
      title: "Understand Customers Better with Multimodal AI",
      description: "Discover how multimodal AI can enhance customer understanding and engagement",
      date: "Jan 23, 2025", // Current date in MMM DD, YYYY format
      thumbnail: "https://miro.medium.com/v2/resize:fit:640/format:webp/0*NoVFZRT61OP5RpMd", // Replace with your article's main image URL
      type: "article",
      url: "https://medium.com/@shaythuram/understand-customers-better-with-multimodal-ai-1137e9eba6c2",
      readTime: "3 min read",
      
    },
    {
      id: 2,
      title: "RAG vs Continual Pretraining",
      description: "Uncover the key differences between Retrieval-Augmented Generation (RAG) and continual pretraining, and how they shape the evolution of AI capabilities.",
      date: "Jan 16, 2025",
      thumbnail: "https://miro.medium.com/v2/resize:fit:800/format:webp/1*9ugaA0vzkAvTyXXfmuEkWA.png",
      type: "article",
      url: "https://medium.com/@shaythuram/rag-vs-continual-pretraining-da939c54d978",
      
      readTime: "3 min read",
      
    },
    {
      id: 2,
      // Template Article (copy and modify this for new articles)
      id: 2,
        title: "Vertical Agents: The Specialized Future of Artificial Intelligence",
        description: "Discover how vertical agents are revolutionizing AI by focusing on specialized expertise over general-purpose functionality.",
      date: "Jan 1, 2025", // Current date in MMM DD, YYYY format
      thumbnail: "https://miro.medium.com/v2/resize:fit:1400/format:webp/0*VBqsKxMWsws3M4tv", // Replace with your article's main image URL
      type: "article",
      url: "https://medium.com/@shaythuram/vertical-agents-the-specialized-future-of-artificial-intelligence-d6db8a662119",
      
      readTime: "3 min read",
      
    },
    {
        id: 3,
        // Template Article (copy and modify this for new articles)
        title: "The Future of AI: Why Specialized Language Models Will Change Everything",
        description: "Explore why the rise of specialized language models is set to transform industries, redefine workflows, and shape the future of AI.",
        date: "Dec 23, 2024", // Current date in MMM DD, YYYY format
        thumbnail: "https://miro.medium.com/v2/resize:fit:828/0*MOUug5MTW98gpQEn", // Replace with your article's main image URL
        type: "article",
        url: "https://medium.com/@shaythuram/the-future-of-ai-why-specialized-language-models-will-change-everything-299111111111",
        
        readTime: "3 min read",
        
      
    },
  ],
  tutorials: [
    {
      id: 1,
      title: "Getting Started with Modal API Keys",
      description: "Learn how to obtain and configure your Modal API keys for GPU-powered model fine-tuning with Tune.",
      date: "Feb 12, 2025",
      thumbnail: modalLogo,
      type: "video",
      videoUrl: "https://drive.google.com/file/d/1dm5Ijdp3Nm5IOq2VGtXb_CsQ1Q8POWAE/preview",
      duration: "2:30",
      readTime: "2:30 min"
    },
    {
      id: 2,
      title: "Setting Up OpenAI API Keys",
      description: "A step-by-step guide to getting and configuring your OpenAI API keys for advanced model evaluation features.",
      date: "Feb 12, 2025",
      thumbnail: openaiLogo,
      type: "video",
      videoUrl: "https://drive.google.com/file/d/1eTSGmkSxe1kwgGJ_C3vW0IiJF6-7Q6lw/preview",
      duration: "3:00",
      readTime: "3:00 min"
    }
  ]
};

export default function BlogsAndTutorials() {
  const [activeTab, setActiveTab] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const getFilteredContent = () => {
    // Ensure tutorials is an array
    const tutorials = content.tutorials || [];
    let items = [...content.articles, ...tutorials];
    if (activeTab === 'articles') items = content.articles;
    if (activeTab === 'tutorials') items = tutorials;

    return items.filter(item => 
      item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <div className="min-h-screen bg-blue-50">
      <header className="bg-white shadow-sm py-4 sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-14">
            <div className="flex-shrink-0 flex items-center cursor-pointer">
              <a href="/" className="Tune-logo text-xl font-bold">Tune</a>
            </div>
            <div className="flex items-center space-x-4 ml-auto">
              {navItems.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="relative px-3 py-2 rounded-md text-sm font-medium transition-colors text-blue-600 hover:text-blue-800"
                >
                  {item.name}
                </a>
              ))}
              <a
                href="https://noteforms.com/forms/tune-waitlist-rum4ca"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
              >
                Join Waitlist
                <ChevronRight className="ml-1 h-4 w-4" />
              </a>
            </div>
          </div>
        </div>
      </header>

      <div className="container mx-auto px-4">
        <div className="pt-12 pb-8">
          <h1 className="text-[32px] font-bold text-blue-900 mb-2">Blogs & Tutorials</h1>
          <p className="text-blue-600">Learn how to make the most of Tune with our guides and video tutorials</p>
        </div>

        <div className="mb-12 flex flex-wrap items-center gap-4">
          <div className="relative flex-grow max-w-md">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
            <input
              type="text"
              placeholder="Search articles and tutorials..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          
          <div className="flex gap-2">
            <button
              onClick={() => setActiveTab('all')}
              className={`px-4 py-2 rounded-lg text-sm font-medium ${
                activeTab === 'all' ? 'bg-blue-600 text-white' : 'bg-blue-100 text-blue-600 hover:bg-blue-200'
              }`}
            >
              All
            </button>
            <button
              onClick={() => setActiveTab('articles')}
              className={`px-4 py-2 rounded-lg text-sm font-medium ${
                activeTab === 'articles' ? 'bg-blue-600 text-white' : 'bg-blue-100 text-blue-600 hover:bg-blue-200'
              }`}
            >
              Articles
            </button>
            <button
              onClick={() => setActiveTab('tutorials')}
              className={`px-4 py-2 rounded-lg text-sm font-medium ${
                activeTab === 'tutorials' ? 'bg-blue-600 text-white' : 'bg-blue-100 text-blue-600 hover:bg-blue-200'
              }`}
            >
              Tutorials
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pb-16">
          {getFilteredContent().length > 0 ? (
            getFilteredContent().map((item) => (
              <div 
                key={item.id} 
                className="group cursor-pointer"
                onClick={() => {
                  if (item.type === 'video') {
                    setSelectedVideo(item);
                  } else {
                    setSelectedArticle(item);
                  }
                }}
              >
                <div className="bg-white rounded-3xl overflow-hidden border border-gray-100 shadow-sm hover:shadow-md transition-all duration-200 h-full flex flex-col">
                  <div className="aspect-[4/3] overflow-hidden relative">
                    <img
                      src={item.thumbnail}
                      alt={item.title}
                      className="w-full h-full object-contain group-hover:scale-105 transition-transform duration-200"
                    />
                    {item.type === 'video' && (
                      <>
                        <div className="absolute inset-0 bg-black bg-opacity-30 group-hover:bg-opacity-40 transition-all duration-200" />
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="w-16 h-16 rounded-full bg-white bg-opacity-90 flex items-center justify-center group-hover:scale-110 transition-transform duration-200">
                            <Play className="h-8 w-8 text-blue-600 ml-1" />
                          </div>
                        </div>
                        <div className="absolute bottom-4 right-4 bg-black bg-opacity-75 px-2 py-1 rounded-md">
                          <span className="text-white text-sm">{item.duration}</span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="p-8 flex-grow flex flex-col justify-between">
                    <div>
                      <h3 className="text-2xl font-semibold text-blue-900 mb-3 leading-tight">
                        {item.title}
                      </h3>
                      <p className="text-gray-600 text-sm mb-4">
                        {item.description}
                      </p>
                    </div>
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      {item.author && <span>{item.author}</span>}
                      {item.author && <span>•</span>}
                      {item.readTime && <span>{item.readTime}</span>}
                      {item.readTime && <span>•</span>}
                      <span>{item.date}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center col-span-full text-gray-500">
              No tutorials available at the moment.
            </div>
          )}
        </div>
      </div>

      {/* Article Preview Modal */}
      {selectedArticle && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto">
          <div className="relative w-full max-w-4xl bg-white rounded-2xl overflow-hidden min-h-[50vh] max-h-[90vh] flex flex-col">
            <div className="relative h-[300px] w-full">
              <img
                src={selectedArticle.thumbnail}
                alt={selectedArticle.title}
                className="w-full h-full object-contain"
              />
              <button 
                onClick={() => setSelectedArticle(null)}
                className="absolute top-4 right-4 bg-black/50 hover:bg-black/70 text-white rounded-full p-2 transition-colors"
                aria-label="Close article"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <div className="p-8 overflow-y-auto">
              <div className="max-w-3xl mx-auto">
                <div className="flex items-center gap-2 text-sm text-gray-500 mb-4">
                  <span>{selectedArticle.author}</span>
                  <span>•</span>
                  <span>{selectedArticle.readTime}</span>
                  <span>•</span>
                  <span>{selectedArticle.date}</span>
                </div>
                
                <h1 className="text-3xl font-bold text-blue-900 mb-4">{selectedArticle.title}</h1>
                <p className="text-lg text-gray-600 mb-8">{selectedArticle.description}</p>
                
                <div className="flex gap-4">
                  <a 
                    href={selectedArticle.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex-1 bg-blue-600 text-white px-6 py-3 rounded-lg text-center hover:bg-blue-700 transition-colors"
                  >
                    Read on Medium
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Video Modal */}
      {selectedVideo?.videoUrl && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="relative w-full max-w-5xl aspect-video bg-black rounded-lg overflow-hidden">
            <button 
              onClick={() => setSelectedVideo(null)}
              className="absolute top-4 right-4 text-white hover:text-gray-200 z-10"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <iframe
              src={selectedVideo.videoUrl}
              className="w-full h-full"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}