/**
 * firebaseUtils.js
 * Enhanced with detailed logging for debugging
 */

import { db, auth , storage} from '../firebase/config';
import { uploadBytes, getDownloadURL } from "firebase/storage";
import { ref as storageRef } from "firebase/storage";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  setDoc,
} from "firebase/firestore";

import { transformData } from './dataTransformers';

// Debug helper
const logData = (label, data) => {
  console.log(`[Firebase Utils] ${label}:`, 
    JSON.stringify(data, (key, value) => 
      typeof value === 'number' ? Number(value.toFixed(6)) : value, 2)
  );
};

/**
 * Fetch completed fine-tuning runs data from Firestore
 */
export async function fetchCompletedRunsData() {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.log("[Firebase Utils] No user logged in");
      return null;
    }
    logData("Current user", { uid: user.uid });

    // Try user document first
    const userDocRef = doc(db, "Runs", user.uid);
    
    //const userDocRef = doc(db, "Runs", "clmpvIpWQzcDNcoxjaXhBcIO3n12");
    let docSnapshot = await getDoc(userDocRef);
    let currentDocRef = userDocRef;

    // Fallback to admin document
    if (!docSnapshot.exists()) {
      console.log("[Firebase Utils] No user document, trying admin document");
      //const adminDocRef = doc(db, "Runs", "admin");
      const adminDocRef = doc(db, "Runs", user.uid);
      docSnapshot = await getDoc(adminDocRef);
      currentDocRef = adminDocRef;
    }

    if (!docSnapshot.exists()) {
      console.log("[Firebase Utils] No documents found");
      return null;
    }

    // Get document data
    const docData = docSnapshot.data();
    logData("Document data", docData);

    const initialized = docData.initialized;
    const subCollectionIDs = docData.subCollections || [];

    logData("Document metadata", {
      initialized,
      subCollectionCount: subCollectionIDs.length,
      subCollectionIDs
    });

    // Collect sub-collection data
    const allSubCollectionData = {};
    let totalDocs = 0;

    for (const subCollectionID of subCollectionIDs) {
      console.log(`[Firebase Utils] Processing sub-collection: ${subCollectionID}`);
      
      const subCollectionRef = collection(currentDocRef, subCollectionID);
      const subCollectionSnapshot = await getDocs(subCollectionRef);

      if (!subCollectionSnapshot.empty) {
        const subCollectionData = {};
        subCollectionSnapshot.forEach(doc => {
          subCollectionData[doc.id] = doc.data();
          totalDocs++;
        });

        if (Object.keys(subCollectionData).length > 0) {
          allSubCollectionData[subCollectionID] = subCollectionData;
        }
      }
    }

    logData("Collection stats", {
      totalSubCollections: Object.keys(allSubCollectionData).length,
      totalDocuments: totalDocs
    });

    // Check data before transformation
    const dataCheck = Object.entries(allSubCollectionData).map(([id, data]) => ({
      id,
      hasAIEval: !!data.AI_Eval,
      hasSTDEval: !!data.STD_Eval,
      hasTuningInfo: !!data.tuning_Info
    }));
    logData("Data structure check", dataCheck);

    // Transform data
    console.log("[Firebase Utils] Starting data transformation...");
    const transformedData = transformData(allSubCollectionData);
    
    // Validate transformed data
    if (!transformedData) {
      console.error("[Firebase Utils] Transformation returned null");
      return null;
    }

    // Check for any undefined or invalid numbers in transformed data
    const validateNumbers = (obj, path = '') => {
      Object.entries(obj).forEach(([key, value]) => {
        const currentPath = path ? `${path}.${key}` : key;
        if (typeof value === 'number') {
          if (isNaN(value) || !isFinite(value)) {
            console.error(`[Firebase Utils] Invalid number at ${currentPath}:`, value);
          }
        } else if (value && typeof value === 'object') {
          validateNumbers(value, currentPath);
        }
      });
    };

    validateNumbers(transformedData);
    logData("Transformation complete", {
      keys: Object.keys(transformedData),
      runCount: Object.values(transformedData)
        .reduce((sum, arr) => sum + arr.length, 0)
    });

    return transformedData;

  } catch (error) {
    console.error("[Firebase Utils] Error fetching data:", error);
    return null;
  }
}

/**
 * Add test data to Firebase
 */
export async function addTestDataToFirebase(userId, testData) {
  try {
    logData("Adding test data", { userId, dataKeys: Object.keys(testData) });
    
    const adminDocRef = doc(db, "Runs", "admin");
    const adminDoc = await getDoc(adminDocRef);
    
    if (!adminDoc.exists()) {
      console.log("[Firebase Utils] Creating new admin document");
      await setDoc(adminDocRef, {
        initialized: true,
        subCollections: [userId]
      });
    } else {
      const currentData = adminDoc.data();
      const subCollections = currentData.subCollections || [];
      if (!subCollections.includes(userId)) {
        console.log("[Firebase Utils] Adding user to subCollections");
        await setDoc(adminDocRef, {
          ...currentData,
          subCollections: [...subCollections, userId]
        });
      }
    }

    const userRunRef = collection(adminDocRef, userId);
    const testRunRef = doc(userRunRef, "testRun1");
    await setDoc(testRunRef, testData);

    console.log('[Firebase Utils] Test data added successfully for user:', userId);
    return true;
  } catch (error) {
    console.error('[Firebase Utils] Error adding test data:', error);
    return false;
  }
}

/**
 * Update user profile
 */
export async function updateUserProfile(userId, profileData) {
  try {
    logData("Updating profile", { userId, dataKeys: Object.keys(profileData) });
    
    const userDocRef = doc(db, 'users', userId);
    await setDoc(userDocRef, {
      ...profileData,
      updatedAt: new Date().toISOString()
    }, { merge: true });
    
    console.log('[Firebase Utils] Profile updated successfully');
    return true;
  } catch (error) {
    console.error('[Firebase Utils] Error updating profile:', error);
    return false;
  }
}



/**
 * Uploads multiple files to Firebase Storage and returns their download URLs.
 * @param {File[]} files - An array of file objects to upload.
 * @param {string} folder - The folder where the files should be stored (default: "uploads").
 * @returns {Promise<string[]>} - An array of download URLs.
 */
export const uploadFiles = async (files, folder = "uploads_tune") => {
  if (!files || files.length === 0) throw new Error("No files provided.");

  try {
    const uploadPromises = files.map(async (file) => {
      const storageRef = storageRef(storage, `uploads/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      return getDownloadURL(snapshot.ref);
    });

    const downloadURLs = await Promise.all(uploadPromises);
    console.log("✅ Files uploaded:", downloadURLs);
    return downloadURLs;
  } catch (error) {
    console.error("❌ Upload error:", error);
    throw error;
  }
};