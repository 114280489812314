import React from 'react';
import { Mail, MessageSquare, MapPin, ExternalLink } from 'lucide-react';
import Header from './ui/Header';

const contactMethods = [
  {
    title: "Email Us",
    icon: Mail,
    description: "Get in touch with our team",
    action: "shaythuram@typeless.io",
    isLink: true,
    href: "mailto:shaythuram@typeless.io"
  },
  {
    title: "Schedule a Call",
    icon: MessageSquare,
    description: "Book a meeting with our team",
    action: "Schedule Meeting",
    isLink: true,
    href: "https://calendly.com/shaythuram-typeless/30min" // Replace with your actual Calendly link
  },
  {
    title: "Visit Us",
    icon: MapPin,
    description: "Our office location",
    action: "Typeless Pte. Ltd., 7 Temasek Boulevard, #12-07, Suntec Tower One, Singapore (038987)",
    isLink: false
  }
];

export default function Contact() {
  return (
    <div className="min-h-screen bg-blue-50">
      <Header />
      
      <main className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-3xl font-bold text-blue-900 mb-4">Contact Us</h1>
            <p className="text-lg text-blue-700">
              We're here to help! Choose your preferred way to reach us.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {contactMethods.map((method, index) => (
              <div 
                key={index}
                className="bg-white rounded-xl shadow-sm p-6 border border-blue-100 hover:shadow-md transition-shadow"
              >
                <div className="flex items-center mb-4">
                  {React.createElement(method.icon, {
                    className: "w-6 h-6 text-blue-600 mr-3"
                  })}
                  <h2 className="text-xl font-semibold text-blue-900">{method.title}</h2>
                </div>
                
                <p className="text-blue-700 mb-4">{method.description}</p>
                
                {method.isLink ? (
                  <a
                    href={method.href}
                    className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium"
                    target={method.href.startsWith('http') ? "_blank" : undefined}
                    rel={method.href.startsWith('http') ? "noopener noreferrer" : undefined}
                  >
                    {method.action}
                    {method.href.startsWith('http') && (
                      <ExternalLink className="w-4 h-4 ml-1" />
                    )}
                  </a>
                ) : (
                  <p className="text-gray-600">{method.action}</p>
                )}
              </div>
            ))}
          </div>

          {/* Contact Form - Temporarily commented out
          <div className="mt-12 bg-white rounded-xl shadow-sm p-8 border border-blue-100">
            <h2 className="text-2xl font-semibold text-blue-900 mb-6">Send us a Message</h2>
            
            <form className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-blue-900 mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    className="w-full px-4 py-2 rounded-lg border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Your name"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-blue-900 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    className="w-full px-4 py-2 rounded-lg border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="your@email.com"
                  />
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-blue-900 mb-2">
                  Subject
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-2 rounded-lg border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="How can we help?"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-blue-900 mb-2">
                  Message
                </label>
                <textarea
                  rows={4}
                  className="w-full px-4 py-2 rounded-lg border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Tell us more about your inquiry..."
                />
              </div>
              
              <button
                type="submit"
                className="w-full md:w-auto px-8 py-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Send Message
              </button>
            </form>
          </div>
          */}
        </div>
      </main>
    </div>
  );
} 