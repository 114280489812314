/**
 * FINE-TUNING FORM COMPONENT
 * 
 * A multi-step form for configuring and submitting fine-tuning jobs
 * 
 * FORM STEPS:
 * 1. Fine-tuning Purpose Selection
 *    - Type selection (QnA, Summary, Text Gen, etc.)
 *    - Data source selection (Upload, HuggingFace, etc.)
 * 
 * 2. Training Preferences
 *    - Training location (Local, Cloud, etc.)
 *    - Silicon optimization options
 *    - Modal/Cloud credentials
 * 
 * 3. Model Saving Configuration
 *    - Save format (HF, GGUF, etc.)
 *    - Repository configuration
 *    - Advanced parameters
 *      - Model selection
 *      - Sequence lengths
 *      - Training parameters
 *      - Quantization settings
 * 
 * 4. Evaluation Configuration
 *    - Dataset selection
 *    - Metric selection
 *      - Loss
 *      - Perplexity
 *      - AI Evaluation
 *      - Data Generation
 *    - AI Evaluation metrics
 *      - G-Eval
 *      - Summarization
 *      - Answer Relevancy
 *      - Bias
 *      - Toxicity
 *      - Tool Correctness
 * 
 * 5. Summary & Submission
 *    - Configuration review
 *    - Creation naming
 *    - Final submission
 * 
 * FORM STATE:
 * {
 *   step: number (1-5),
 *   finetuneType: string,
 *   dataSource: string,
 *   selectedDataset: string,
 *   hfApiKey: string,
 *   hfDatasetUrl: string,
 *   trainingLocation: string,
 *   modelSaving: string,
 *   evaluationDataset: File[],
 *   deployToVLLM: boolean,
 *   evaluationMetric: string,
 *   customMetric: string,
 *   optimizeForSilicon: boolean,
 *   modalSecret: string,
 *   modalAccessKey: string,
 *   hfRepoName: string,
 *   advancedOptions: {
 *     model: string,
 *     max_seq_length: number,
 *     generation_length: number,
 *     r: number,
 *     lora_alpha: number,
 *     weight_decay: number,
 *     test_size: number,
 *     logging_steps: number,
 *     lr_scheduler_type: string,
 *     optim: string,
 *     quantization_method: string
 *   },
 *   evaluationMetrics: {
 *     loss: boolean,
 *     perplexity: boolean,
 *     aiEval: boolean,
 *     DataGeneration: boolean,
 *     GenePDF: boolean
 *   },
 *   aiEvalMetrics: {
 *     geval: boolean,
 *     summarisation: boolean,
 *     answerRelevancy: boolean,
 *     bias: boolean,
 *     toxicity: boolean,
 *     toolCorrectness: boolean
 *   }
 * }
 * 
 * FIREBASE INTEGRATION:
 * - Stores form data in Firestore
 * - Associates data with authenticated user
 * - Tracks form completion status
 */

'use client';

import React, { useState, useMemo, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { uploadFile } from "./FileUpload";
import { X } from "lucide-react";
import Header from "./ui/Header";
import {
  Upload,
  ChevronRight,
  ChevronLeft,
  ExternalLink,
  ChevronUp,
  ChevronDown,
  Eye,
  EyeOff,
  HelpCircle,
} from "lucide-react";
import { UploadCloud, Trash2 } from "lucide-react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Switch,
  IconButton,
  InputAdornment,
  Link,
  Divider,
  Tooltip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  ownerDocument,
} from "@mui/material";
import {
  LinearProgress,
  Collapse,
  ListItemSecondaryAction
} from "@mui/material";


import {  uploadBytes, getDownloadURL , deleteObject } from "firebase/storage";
import { uploadFiles } from "../utils/firebaseUtils";
import { submitFormHandler } from "./submitFormHandler";
import { getAuth } from "firebase/auth";
import { getDatabase, onDisconnect, set, update } from "firebase/database";
import { ref as storageRef } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db, auth , storage} from '../firebase/config';
import GuidanceBox, { getGuidanceMessage } from './GuidanceBox';


const DATASETS = [
  { model: 'iamtarun/python_code_instructions_18k_alpaca', purpose: 'QnA', topic: 'Py Code', name: 'A dataset of Python programming instructions and solutions for code generation tasks.', link: 'https://huggingface.co/datasets/iamtarun/python_code_instructions_18k_alpaca', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
  { model: 'Amod/mental_health_counseling_conversations', purpose: 'QnA', topic: 'Therapy', name: 'A collection of mental health counseling dialogues for improving therapy and empathetic conversation systems.', link: 'https://huggingface.co/datasets/Amod/mental_health_counseling_conversations', input_col: 'Context', output_col: 'Response', instruction_col: "Context" },
  { model: 'bio-nlp-umass/bioinstruct', purpose: 'QnA', topic: 'Biology', name: 'A biology-specific instruction dataset with 25k examples for multi-task learning in medical, clinical, and healthcare NLP.', link: 'https://huggingface.co/datasets/bio-nlp-umass/bioinstruct', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
  { model: 'knkarthick/samsum', purpose: 'Summary', topic: 'Linguistics', name: 'A conversational summarization dataset of over 16k dialogues for creating concise summaries of text conversations.', link: 'https://huggingface.co/datasets/knkarthick/samsum', input_col: 'dialogue', output_col: 'summary', instruction_col: "dialogue" },
  { model: 'allenai/multinews_sparse_oracle', purpose: 'Summary', topic: 'News', name: 'A dataset of news articles and their summaries, optimized for sparse oracle summarization tasks.', link: 'https://huggingface.co/datasets/allenai/multinews_sparse_oracle', input_col: 'document', output_col: 'summary', instruction_col: "document" },
  { model: 'Trelis/big_patent_sample', purpose: 'Summary', topic: 'Patent', name: 'A dataset of sampled U.S. patent descriptions and their human-written abstractive summaries for patent text summarization tasks.', link: 'https://huggingface.co/datasets/Trelis/big_patent_sample?row=3', input_col: 'description', output_col: 'abstract', instruction_col: "description" },
  { model: 'Sp1786/multiclass-sentiment-analysis-dataset', purpose: 'Sentiment Analysis', topic: 'Text Messages', name: 'A dataset for multiclass sentiment analysis tasks, with labeled text data for positive, negative, and neutral sentiments.', link: 'https://huggingface.co/datasets/Sp1786/multiclass-sentiment-analysis-dataset?row=6', input_col: 'text', output_col: 'sentiment', instruction_col: "text" },
  { model: 'juanka0357/bitcoin-sentiment-analysis', purpose: 'Sentiment Analysis', topic: 'Bitcoin', name: 'A dataset for sentiment analysis of Bitcoin-related tweets to classify opinions as positive, negative, or neutral.', link: 'https://huggingface.co/datasets/juanka0357/bitcoin-sentiment-analysis', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
  {model: 'Shay1309/medical_data' , purpose: 'QnA', topic: 'Medical', name: 'A dataset of open-ended verifiable medical questions and answers for diagnostic and educational use.', link: "https://huggingface.co/datasets/Shay1309/medical_data", input_col: 'Question', output_col: "Answer", instruction_col: 'Question' },
];


const defaultAdvancedOptions = {
  model: "mistralai/Mistral-7B-v0.1",
  output_dir: "./results",
  per_device_train_batch_size: 4,
  per_device_eval_batch_size: 4,
  gradient_accumulation_steps: 4,
  Epochs: 1,
  "Learning Rate": 2e-4,
  fp16: true,
  logging_dir: "./logs",
  remove_unused_columns: false,
};

const EvalTooltips = {
  geval:
    "G-Eval is a general-purpose evaluation framework for language models.",
  summarisation:
    "Evaluates the model's ability to generate concise and accurate summaries.",
  answerRelevancy:
    "Assesses how relevant and accurate the model's answers are to given questions.",
  bias: "Detects and measures potential biases in the model's outputs.",
  toxicity:
    "Evaluates the model's tendency to generate toxic or inappropriate content.",
  // toolCorrectness:
  //   "Assesses the accuracy of the model in using tools or calling functions.",
  loss: "Loss is an assessment of how close the model's predictions are to the expected output sequence",
  perplexity:
    "Perplexity measures how confident the model is in its predictions and how well it understands the language. Lower is better",
};

const params = {
  model:
    "Model: The pretrained model you want to fine-tune. Different models vary in size and training focus.",
  "Max Sequence Length":
    "Max Sequence Length: The maximum number of tokens (words or characters) in each input sequence. Longer lengths allow more context but increase computation.",
  "Generation Length":
    "Generation Length: The number of tokens the model generates in response to a prompt. Higher values generate longer responses.",
  Rank: "Rank: Controls the number of trainable parameters in fine-tuning. Smaller values (e.g., 4-8) use fewer resources and are suitable for simpler tasks. Larger values (e.g., 16-32) increase adaptability for complex tasks but require more memory. Default: 8. Adjust based on task complexity and hardware capacity.",
  "Lora Alpha":
    "Lora Alpha: Determines the strength of fine-tuned weight updates relative to the base model. Choose:\n 1 (Default): Balanced for most tasks, including small datasets or simple applications.\n 2: Increases adaptation strength, ideal for complex tasks or large datasets requiring stronger fine-tuning. Use cautiously if training becomes unstable. ",
  "Weight Decay":
    "Weight Decay: Controls the penalty applied to model weights during fine-tuning to prevent overfitting. Recommended range: 0.0 - 0.1 (default: 0.01). Use higher values (e.g., 0.01 - 0.1) for smaller datasets to encourage generalization and lower values (e.g., 0.001 - 0.01) for larger datasets. Setting 0.0 disables regularization but may increase the risk of overfitting. Choose carefully based on your dataset size and task.",
  "Evaluation Split":
    "Evaluation Split: The proportion of data reserved for evaluation. 1 means that 1% of the data is used to evaluate the model.",
  "Logging Steps":
    "Logging Steps: Frequency of logging the model's performance metrics. Lower values provide more frequent updates but increase overhead.",
  "Learning Rate Scheduler Type":
    "Learning Rate Scheduler Type: Strategy for adjusting the learning rate during training. 'Linear' gradually decreases the rate as training progresses.",
    Optimizer:
    "Optimizer: The optimization algorithm used for training. 'adamw_8bit' reduces memory usage and is suitable for large models.",
  "Quantization Method":
    "Quantization Method: Reduces model size and memory usage by representing weights in lower precision. 'Quantized' is a recommended option for most cases.",
  "Learning Rate":
    "Learning Rate: Determines the rate of parameter updates during training. Recommended range depends on task and model size. Higher values (e.g., >2e-4) enable faster convergence but risk instability. Lower values (e.g., <2e-4) provide stable, precise updates but may slow down training. Default: 2e-4. Adjust based on your dataset and training stability.",
  "Epochs":
    "Epochs: The number of times the entire dataset is passed through the model during training. Higher values (e.g., 3-5) allow the model to learn more from the data but may increase training time. Lower values (e.g., 1-2) reduce training time but may limit the model's ability to learn. Default: 1. Adjust based on your dataset size and training stability.",


};


export default function Component() {
  const navigate = useNavigate();
  const [hasExistingRuns, setHasExistingRuns] = React.useState(false);
  

  React.useEffect(() => {
    const checkExistingRuns = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          
          // Check the admin document for user's runs
          const adminDocRef = doc(db, "Runs",  user.uid);   
          const adminDoc = await getDoc(adminDocRef);
          if (adminDoc.exists()) {
            const data = adminDoc.data();
            const subCollections = data.subCollections || [];
            setHasExistingRuns(subCollections.includes(user.uid));
          }
        }
      } catch (error) {
        console.error("Error checking existing runs:", error);
      }
    };

    checkExistingRuns();
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(`FineTuningForm: User logged in - ${user.email}`);
      } else {
        console.log("FineTuningForm: No user is logged in");
      }
    });

    return () => unsubscribe(); // Cleanup
  }, []);

  const methods = useForm({
    defaultValues: {
      creationName: "",
      step: 0,
      hasDocuments: false,
      ownDocuments: [],
      finetuneType: "",
      instructionCol:'',
      dataSource: "",
      selectedDataset: "",
      hfApiKey: "",
      hfDatasetUrl: "",
      trainingLocation: "",
      modelSaving: "",
      validationDataset: null,
      deployToVLLM: false,
      evaluationMetric: "",
      customMetric: "",
      optimizeForSilicon: false,
      modalSecret: "",
      modalAccessKey: "",
      hfRepoName: "",
      showAdvancedParams: false,
      advancedOptions: {
        model: "unsloth/Phi-3.5-mini-instruct",
        Epochs: 1,
        "Max Sequence Length": 2048,
        "Generation Length": 1024,
        Rank: 16,
        "Lora Alpha": 1,
        "Weight Decay": 0.01,
        "Evaluation Split": 0.005,
        "Logging Steps": 25,
        "Learning Rate Scheduler Type": "linear",
        Optimizer: "adamw_8bit",
        "Quantization Method": "q8_0",
        "Learning Rate": 2e-4,
      },

      advancedOptions_CPT: {
        model: "unsloth/Phi-3.5-mini-instruct",
        Epochs: 1,
        "Max Sequence Length": 2048,
        "Semantic Chunk Size": 500,
        "Chunk Overlap": 50,
        "Max Chunk Length": 512,
        Rank: 128,
        "Lora Alpha": 0.25,
        "Weight Decay": 0.01,
        "Embedding Learning Rate": 5e-6,
        "Logging Steps": 25,
        "Learning Rate Scheduler Type": "linear",
        Optimizer: "adamw_8bit",
        
        "Learning Rate": 5e-5,
        
      },

      evaluationDatasetType: "",
      Eval_HF_URL: "",
      evaluationMetrics: {
        loss: true,
        perplexity: false,
        aiEval: false,
        DataGeneration: false,
        GenePDF: false,
      },
      openAiApiKey: "",
      showOpenAiApiKey: false,
      aiEvalMetrics: {
        geval: false,
        summarisation: false,
        answerRelevancy: false,
        bias: false,
        toxicity: false,
        // toolCorrectness: false,
      },
    },
  });

  const { watch, setValue, handleSubmit, getValues } = methods;
  const formValues = watch();
  const [showApiKey, setShowApiKey] = useState(false);
  const [showHfApiKey, setShowHfApiKey] = useState(false);
  const [formState, setFormState] = useState({});
  


  const filteredDatasets = React.useMemo(() => {
    return DATASETS.filter(
      (dataset) => dataset.purpose === formValues.finetuneType
    );
  }, [formValues.finetuneType]);

  const selectedDataset = React.useMemo(() => {
    return DATASETS.find(
      (dataset) => dataset.model === formValues.selectedDataset
    );
  }, [formValues.selectedDataset]);

  const handleInputChange = (field, value) => {
    setValue(field, value);
    console.log(`Input Change - ${field}:`, value);
    console.log("Current formValues:", getValues());

    if (field === "finetuneType") {
      if (["Summary", "Text Gen", "Sentiment Analysis"].includes(value)) {
        setValue("evaluationDatasetType", "use-training");
        setValue("generateEvaluationDataset", false); // Ensure switch is off
      }
    }

    if (field === "dataSource") {
      if (value === "provided") {
        // Clear Hugging Face specific fields
          setValue("hfDatasetUrl", "");
          setValue("hfApiKey", "");
          setValue("inputCol", "");
          setValue("instructionCol", "");
          setValue("outputCol", "");
      } else if (value === "huggingface") {
        setValue("hfDatasetUrl", "");
        setValue("hfApiKey", "");
        setValue("inputCol", "");
        setValue("instructionCol", "");
        setValue("outputCol", "");
        setValue("selectedDataset", "");
        
      }
    }

    if (field === "modelSaving") {
      if (['convert-gguf-local', 'download'].includes(value)) {
        setValue("hfRepoName", "");
      }
    }

    // Reset Eval_HF_URL when toggling evaluationDatasetType
    if (field === "evaluationDatasetType") {
      setValue("Eval_HF_URL", "");
    }
  };

  const handleAdvancedParamChange = (option, value) => {
    
    setValue(`advancedOptions.${option}`, value)
  }

  const handleAdvancedParamChange_CPT = (option, value) => {
    setValue(`advancedOptions_CPT.${option}`, value)
  }

  const MAX_FILE_SIZE_MB = 10;
  const [files, setFiles] = useState([]);
  const [showFiles, setShowFiles] = useState(false);

  // Calculate total file size
  const totalFileSize = files.reduce((acc, file) => acc + parseFloat(file.size), 0);


  

  const handleFileUpload = async (e) => {
    const files = e.target.files; // Get selected files
  
    if (!files || files.length === 0) {
      console.error("No files selected.");
      return;
    }
  
    let uploadedFiles = [];
  
    for (let file of files) {
      const ref = storageRef(storage, `uploads/${file.name}`); // ✅ Correct reference
  
      try {
        const snapshot = await uploadBytes(ref, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log("✅ Uploaded Image URL:", downloadURL);
  
        uploadedFiles.push({ name: file.name, url: downloadURL , size: (file.size / (1024 * 1024)).toFixed(2)}); 
        formValues.ownDocuments.push(downloadURL);
        console.log("✅ Own Documents:", formValues.ownDocuments );
      } catch (error) {
        console.error("❌ Upload failed:", error);
      }
    }
  
    // ✅ Update state with uploaded files
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };
  
  
  const handleFileRemove = async (index) => {
    setFiles((prevFiles) => {
      const fileToRemove = prevFiles[index];
      formValues.ownDocuments.splice(index, 1);
      console.log("✅ Own Documents Removed sorted:", formValues.ownDocuments);
  
      if (!fileToRemove || !fileToRemove.name) {
        console.error("❌ File not found or has no name.");
        return prevFiles;
      }
  
      const fileRef = storageRef(storage, `uploads/${fileToRemove.name}`);
  
      // Attempt to delete from Firebase Storage
      deleteObject(fileRef)
        .then(() => {
          console.log(`🗑️ File deleted: ${fileToRemove.name}`);
        })
        .catch((error) => {
          console.error("❌ Delete failed:", error);
        });
  
      // Remove from state
      return prevFiles.filter((_, i) => i !== index);
    });
  };





  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleMetricChange = (metric) => {
    setValue(`evaluationMetrics.${metric}`, !formValues.evaluationMetrics[metric])
  }

  const handleAiEvalMetricChange = (metric) => {
    if (formValues.finetuneType === "Summary" && metric !== "summarisation") {
        
        return; // Prevent toggling any metric except Summarisation when Summary is chosen
    }
    setValue(`aiEvalMetrics.${metric}`, !formValues.aiEvalMetrics[metric]);
};

  const handleSaveProfile = () => {
    //setIsEditing(false);
    // Add your save logic here
  };

  const { step } = formValues;

  const validateStep = (step) => {

    if (step === 0) {
      if (formValues.hasDocuments) {
        if (formValues.ownDocuments.length === 0) {
          alert("Please upload at least one document before proceeding.");
          return false;
        }
        return true; 
      }
      if  (!formValues.hasDocuments) {
         return true;
      }
      return false; // If no valid data source is selected
    }

    if (step === 1) {
      if (formValues.skipFineTuneSelection) {
        return true; // Allow skipping this step
      }
      if (formValues.dataSource === 'provided') {
        return formValues.finetuneType && formValues.selectedDataset;
      } else if (formValues.dataSource === 'huggingface') {
        return formValues.finetuneType &&
               formValues.hfDatasetUrl &&
               formValues.hfApiKey &&
               formValues.inputCol &&
               formValues.instructionCol &&
               formValues.outputCol;
      }
      return false; // If no valid data source is selected
    }

    if (step === 2) {
      return formValues.trainingLocation && formValues.modalSecret && formValues.modalAccessKey;
    }

    if (step === 3) {
      if (!formValues.modelSaving) {
        alert("Please select a model saving option before proceeding.");
        return false;
      }
      if (['save-hf', 'convert-gguf-hf'].includes(formValues.modelSaving)) {
        return formValues.hfRepoName && formValues.hfApiKey;
      }
      return true;
    }

    if (step === 4) {
      if (!formValues.evaluationDatasetType) {
        alert("Please select an evaluation dataset type before proceeding.");
        return false;
      }
      if (formValues.evaluationDatasetType === 'huggingface') {
        if (!formValues.Eval_HF_URL || !formValues.hfApiKey || !formValues.openAiApiKey) {
          alert("Please fill in all required fields for Hugging Face evaluation before proceeding.");
          return false;
        }
        if (formValues.evaluationMetrics.aiEval) {
          const isAnyAiEvalMetricChecked = Object.values(formValues.aiEvalMetrics).some(value => value);
          if (!formValues.openAiApiKey || !isAnyAiEvalMetricChecked) {
            alert("Please provide an OpenAI API key and select at least one AI-Eval metric before proceeding.");
            return false;
          }
        }
        return true;
      }
      if (formValues.evaluationDatasetType === 'use-training') {
        if (formValues.generateEvaluationDataset && !formValues.openAiApiKey) {
          alert("Please provide an OpenAI API key for enhancing with synthetic data.");
          return false;
        }
        if (formValues.evaluationMetrics.aiEval) {
          const isAnyAiEvalMetricChecked = Object.values(formValues.aiEvalMetrics).some(value => value);
          if (!formValues.openAiApiKey || !isAnyAiEvalMetricChecked) {
            alert("Please provide an OpenAI API key and select at least one AI-Eval metric before proceeding.");
            return false;
          }
        }
        return true;
      }
      return true;
    }

    const requiredFields = {
      2: ['trainingLocation', 'modalSecret', 'modalAccessKey'],
    };

    const fieldsToCheck = requiredFields[step] || [];
    return fieldsToCheck.every(field => formValues[field]);
  };

  const handleNextStep = () => {
    if (validateStep(formValues.step)) {
      handleInputChange("step", formValues.step + 1);
    } else {
      alert("Please fill in all required fields before proceeding.");
    }
  };

  // Ensure aiEvalMetrics are set correctly when finetuneType is "Summary"
  useEffect(() => {
    if (formValues.finetuneType === "Summary") {
        setValue("aiEvalMetrics.summarisation", true); // Force Summarisation to be selected
        Object.keys(formValues.aiEvalMetrics).forEach((key) => {

            if (key !== "summarisation") {
                
                setValue(`aiEvalMetrics.${key}`, false); // Disable all other metrics
            }
        });
    }
    else {
      setValue("aiEvalMetrics.summarisation", false); // Disable Summarisation when not Summary
    } 
}, [formValues.finetuneType]);

  useEffect(() => {
    if (["Summary", "Text Gen", "Sentiment Analysis", "Knowledge Injection"].includes(formValues.finetuneType)) {
      setValue("generateEvaluationDataset", false);
    }
  }, [formValues.finetuneType]);

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          minHeight: "100vh",
          bgcolor: "rgb(219,234,254)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header showDashboardButton={hasExistingRuns} />
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 4,
          }}
        >
          <Card
            sx={{
              width: "100%",
              maxWidth: "1000px",
              borderRadius: 4,
              boxShadow:
                "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)",
              p: 6,
            }}
          >
            <CardContent sx={{ p: 2 }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: "rgb(30,58,138)", fontWeight: "bold", mb: 4 }}
              >
                {formValues.step === 0 && <div style={{ textAlign: "center" }}>Are there some documents you'd like to finetune the model on?</div>}
                {formValues.step === 1 && "What would you like to finetune the model for?"}
                {formValues.step === 2 && "Finetuning Preferences"}
                {formValues.step === 3 && "Model Saving and Conversion"}
                {formValues.step === 4 && "Evaluation  Management"}
                {formValues.step === 5 && "Form Submission Summary"}
              </Typography>
              



              {formValues.step === 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 4, width: "100%" }}>
                  <Box sx={{ display: "flex", gap: 3, width: "100%", justifyContent: "center" }}>
                    {/* Option Cards */}
                    <Card 
                      sx={{ 
                        width: 300,
                        cursor: 'pointer',
                        border: formValues.hasDocuments ? '2px solid rgb(59,89,238)' : '1px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: 2,
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          borderColor: 'rgb(59,89,238)',
                          transform: 'translateY(-2px)',
                          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
                        }
                      }}
                      onClick={() => handleInputChange("hasDocuments", true)}
                    >
                      <CardContent sx={{ textAlign: 'center', p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Yes
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          I have documents (PDFs, Word files) that I want to use for training the model.
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card 
                      sx={{ 
                        width: 300,
                        cursor: 'pointer',
                        border: !formValues.hasDocuments ? '2px solid rgb(59,89,238)' : '1px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: 2,
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          borderColor: 'rgb(59,89,238)',
                          transform: 'translateY(-2px)',
                          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
                        }
                      }}
                      onClick={() => {
                        handleInputChange("hasDocuments", false);
                        setFiles([]);
                        handleInputChange("ownDocuments", []);
                      }}
                    >
                      <CardContent sx={{ textAlign: 'center', p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          No
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          I want to use existing datasets for training the model.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>

                  {/* Replace the old guidance box with GuidanceBox component */}
                  {(formValues.hasDocuments === true || formValues.hasDocuments === false) && (
                    <GuidanceBox 
                      message={formValues.hasDocuments ? 
                        "Please drag and drop your documents before proceeding." :
                        "Great! We'll show you our pre-built datasets in the next step."}
                      action={formValues.hasDocuments && files.length === 0 ? 
                        "Upload documents, then click Next" : 
                        "Click Next to proceed"}
                    />
                  )}

                  {/* File Upload Area - Only show if Yes is selected */}
                  {formValues.hasDocuments && (
                    <>
                      <Card
                        sx={{
                          mt: 2,
                          width: "500px",
                          border: "2px dashed",
                          borderColor: "grey.400",
                          borderRadius: 2,
                          p: 6,
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          backgroundColor: "rgba(219, 234, 254, 0.2)",
                          "&:hover": { backgroundColor: "rgba(219, 234, 254, 0.4)" },
                        }}
                        onDrop={(e) => handleFileUpload(e)}
                        onDragOver={(e) => e.preventDefault()}
                        onClick={() => document.getElementById("file-upload").click()}
                      >
                        <UploadCloud size={48} color="rgb(30,58,138)" strokeWidth={1.5} />
                        <Typography variant="body1" sx={{ mt: 2, fontWeight: "bold" }}>
                          Drag and drop your documents here
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Only PDF and Word documents (Max 10MB)
                        </Typography>
                        <input
                          id="file-upload"
                          type="file"
                          style={{ display: "none" }}
                          accept=".pdf,.doc,.docx"
                          multiple
                          onChange={(e) => handleFileUpload(e)}
                        />
                      </Card>

                      {/* Progress Bar */}
                      {files.length > 0 && (
                        <Box sx={{ width: "100%", maxWidth: "500px", mt: 2 }}>
                          <LinearProgress variant="determinate" value={(totalFileSize / MAX_FILE_SIZE_MB) * 100} />
                          <Typography variant="body2" sx={{ textAlign: "center", mt: 1 }}>
                            Total Uploaded Size: {totalFileSize.toFixed(2)} MB / {MAX_FILE_SIZE_MB} MB
                          </Typography>
                        </Box>
                      )}

                      {/* Uploaded Files List */}
                      {files.length > 0 && (
                        <Box sx={{ mt: 2, width: "100%", maxWidth: "500px" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              cursor: "pointer",
                              backgroundColor: "rgba(243, 246, 255, 0.8)",
                              p: 2,
                              borderRadius: 2,
                              border: "1px solid rgba(0, 0, 0, 0.1)",
                              transition: "all 0.2s ease",
                              "&:hover": { backgroundColor: "rgba(243, 246, 255, 1)" },
                            }}
                            onClick={() => setShowFiles(!showFiles)}
                          >
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                              Uploaded Files ({files.length})
                            </Typography>
                            {showFiles ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                          </Box>

                          <Collapse in={showFiles}>
                            <List sx={{ mt: 1, backgroundColor: "#fff", borderRadius: 2, boxShadow: "0px 2px 4px rgba(0,0,0,0.1)" }}>
                              {files.map((file, index) => (
                                <ListItem key={index} sx={{ borderBottom: "1px solid #ddd" }}>
                                  <ListItemText
                                    primary={file.name}
                                    secondary={`${file.size} MB`}
                                    primaryTypographyProps={{ fontWeight: "bold" }}
                                  />
                                  <ListItemSecondaryAction>
                                    <IconButton color="error" onClick={() => handleFileRemove(index)}>
                                      <Trash2 size={20} />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              )}




{formValues.step === 1 && (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
    
    {formValues.hasDocuments  && (
      <FormControlLabel
        control={
          <Checkbox
            checked={formValues.skipFineTuneSelection}
            onChange={(e) => {
              const skip = e.target.checked;
              handleInputChange("skipFineTuneSelection", skip);
              if (skip) {
                handleInputChange("finetuneType", "Knowledge Injection");
                handleInputChange("dataSource", "NULL");
                handleInputChange("selectedDataset", "NULL");
                console.log(formValues.finetuneType);
              } else {
                handleInputChange("finetuneType", "");
                handleInputChange("dataSource", "");
                handleInputChange("selectedDataset", "");
              }
            }}
          />
        }
        label="Skip this step : Only If You Can't fine a suitable dataset for your usecase"
      />
    )}

    {!formValues.skipFineTuneSelection && (
      <>
        <FormControl fullWidth required>
          <InputLabel id="finetune-type-label">
            Select a Fine-Tuning Purpose
          </InputLabel>
          <Select
            labelId="finetune-type-label"
            value={formValues.finetuneType}
            onChange={(e) =>
              handleInputChange("finetuneType", e.target.value)
            }
            label="Select a fine-tuning type"
            required
          >
            <MenuItem value="QnA">Question Answering</MenuItem>
            <MenuItem value="Summary">Summarization</MenuItem>
            <MenuItem value="Sentiment Analysis">
              Sentiment Analysis
            </MenuItem>
          </Select>
        </FormControl>

        {/* Replace the old guidance box with GuidanceBox component */}
        {formValues.finetuneType && (
          <GuidanceBox 
            message={!formValues.dataSource ? 
              "Great choice! Now select a data source for your model." :
              formValues.dataSource === "provided" ?
              "Choose from our curated datasets that match your use case." :
              "Enter your Hugging Face dataset details to proceed."}
            action={!formValues.dataSource ? 
              "Select a data source below" :
              formValues.dataSource === "provided" && !formValues.selectedDataset ?
              "Choose a dataset from the list" :
              formValues.dataSource === "huggingface" && (!formValues.hfDatasetUrl || !formValues.hfApiKey) ?
              "Fill in the Hugging Face details" :
              "Click Next to proceed"}
          />
        )}

        {formValues.finetuneType && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Typography variant="h6">Select a Data Source</Typography>
            <FormControl fullWidth>
              <InputLabel id="data-source-label">Select data source</InputLabel>
              <Select
                labelId="data-source-label"
                value={formValues.dataSource}
                onChange={(e) =>
                  handleInputChange("dataSource", e.target.value)
                }
                label="Select data source"
                required
              >
                {filteredDatasets.length > 0 && (
                  <MenuItem value="provided">
                    Choose from provided datasets
                  </MenuItem>
                )}
                <MenuItem value="upload" disabled>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    Upload your own dataset (CSV/Excel, max 10MB)
                    <span style={{
                      backgroundColor: 'rgb(219,234,254)',
                      color: 'rgb(29,78,216)',
                      padding: '2px 8px',
                      borderRadius: '12px',
                      fontSize: '0.75rem',
                      fontWeight: 'medium',
                      marginLeft: '8px'
                    }}>
                      Coming Soon
                    </span>
                  </Box>
                </MenuItem>
                <MenuItem value="huggingface">
                  Use your own Hugging Face dataset
                </MenuItem>
              </Select>
            </FormControl>

            {formValues.dataSource === 'provided' && filteredDatasets.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth required>
                  <InputLabel id="dataset-label">Select a dataset</InputLabel>
                  <Select
                    labelId="dataset-label"
                    value={formValues.selectedDataset}
                    onChange={(e) => {
                      handleInputChange('selectedDataset', e.target.value);
                      const selected = filteredDatasets.find(dataset => dataset.model === e.target.value);
                      if (selected) {
                        handleInputChange('inputCol', selected.input_col || "input");
                        handleInputChange('outputCol', selected.output_col || "output");
                        handleInputChange('instructionCol', selected.instruction_col || "instruction");
                      }
                    }}
                    label="Select a dataset"
                    required
                  >
                    {filteredDatasets.map((dataset) => (
                      <MenuItem key={dataset.model} value={dataset.model}>
                        {dataset.topic} - {dataset.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {selectedDataset && (
                  <Card sx={{ mt: 2 }}>
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 1 }}>Topic: {selectedDataset.topic}</Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>Name: {selectedDataset.name}</Typography>
                      <Link
                        href={selectedDataset.link}
                        target="_blank"
                        rel="noopener"
                        sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                      >
                        View Dataset on Hugging Face <ExternalLink size={16} sx={{ ml: '0.25rem' }} />
                      </Link>
                    </CardContent>
                  </Card>
                )}
              </Box>
            )}

            {formValues.dataSource === "huggingface" && (
              <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                <TextField
                  label="Hugging Face Dataset Name"
                  type="url"
                  value={formValues.hfDatasetUrl}
                  onChange={(e) =>
                    handleInputChange("hfDatasetUrl", e.target.value)
                  }
                  placeholder="Enter the Hugging Face dataset URL"
                  fullWidth
                  required
                />
                <TextField
                  label="Hugging Face API Key"
                  type={formValues.showHfApiKey ? "text" : "password"}
                  value={formValues.hfApiKey}
                  onChange={(e) =>
                    handleInputChange("hfApiKey", e.target.value)
                  }
                  placeholder="Enter your Hugging Face API key"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleInputChange("showHfApiKey", !formValues.showHfApiKey)
                          }
                          edge="eye"
                        >
                          {formValues.showHfApiKey ? (
                            <VisibilityOff size={20} />
                          ) : (
                            <Visibility size={20} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box>
                  <TextField
                    label="Input Column"
                    type="text"
                    value={formValues.inputCol}
                    onChange={(e) => handleInputChange('inputCol', e.target.value)}
                    placeholder="Enter your input column"
                    fullWidth
                    required
                  />
                  <Tooltip title="Column relating to input sequence passed into your model" arrow placement="top">
                    <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                      <HelpCircle size={16} />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box>
                  <TextField
                    label="Instruction Column"
                    type="text"
                    value={formValues.instructionCol}
                    onChange={(e) => handleInputChange('instructionCol', e.target.value)}
                    placeholder="Enter your instruction column"
                    fullWidth
                    required
                  />
                  <Tooltip title="Column relating to a system prompt that you start your model with. If you don't have one, use the same column name as the input column" arrow placement="top">
                    <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                      <HelpCircle size={16} />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box>
                  <TextField
                    label="Output Column"
                    type="text"
                    value={formValues.outputCol}
                    onChange={(e) => handleInputChange('outputCol', e.target.value)}
                    placeholder="Enter your output column"
                    fullWidth
                    required
                  />
                  <Tooltip title="Column relating to output sequence that you expect your model to generate" arrow placement="top">
                    <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                      <HelpCircle size={16} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </>
    )}
  </Box>
)}


              {formValues.step === 2 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <Typography variant="h6">
                    Where would you like to conduct finetuning?
                  </Typography>
                  <RadioGroup
                    value={formValues.trainingLocation}
                    onChange={(e) =>
                      handleInputChange("trainingLocation", e.target.value)
                    }
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <FormControlLabel
                        value="local"
                        control={<Radio disabled />}
                        label="Locally (Optimised for GPU/Mac Silicon)"
                      />
                      <span style={{
                        backgroundColor: 'rgb(219,234,254)',
                        color: 'rgb(29,78,216)',
                        padding: '2px 8px',
                        borderRadius: '12px',
                        fontSize: '0.75rem',
                        fontWeight: 'medium',
                      }}>
                        Coming Soon
                      </span>
                    </Box>
                    <FormControlLabel
                      value="cloud_modal"
                      control={<Radio />}
                      label="Cloud (Modal)"
                    />
                  </RadioGroup>

                  {/* Guidance Box for Step 2 */}
                  {formValues.trainingLocation && (
                    <GuidanceBox 
                      message={formValues.trainingLocation === "cloud_modal" && !formValues.modalSecret ? 
                        "Please provide your Modal credentials to proceed." :
                        "Great! Your training environment is set."}
                      action={formValues.trainingLocation === "cloud_modal" && (!formValues.modalSecret || !formValues.modalAccessKey) ?
                        "Enter your Modal Secret and Token ID" :
                        "Click Next to proceed"}
                    />
                  )}

                  {formValues.trainingLocation === "cloud_modal" && (
                    <Box sx={{ mt: 2, ml: 4, display: "flex", flexDirection: "column", gap: 2 }}>
                      <TextField
                        label="Modal Secret"
                        type={formValues.showModalSecret ? "text" : "password"}
                        value={formValues.modalSecret}
                        onChange={(e) => handleInputChange("modalSecret", e.target.value)}
                        placeholder="Enter your Modal secret"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle secret visibility"
                                onClick={() => handleInputChange("showModalSecret", !formValues.showModalSecret)}
                                edge="end"
                              >
                                {formValues.showModalSecret ? <VisibilityOff size={20} /> : <Visibility size={20} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Modal Token ID"
                        type={formValues.showModalAccessKey ? "text" : "password"}
                        value={formValues.modalAccessKey}
                        onChange={(e) => handleInputChange("modalAccessKey", e.target.value)}
                        placeholder="Enter your Modal access key"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle access key visibility"
                                onClick={() => handleInputChange("showModalAccessKey", !formValues.showModalAccessKey)}
                                edge="end"
                              >
                                {formValues.showModalAccessKey ? <VisibilityOff size={20} /> : <Visibility size={20} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}

              {formValues.step === 3 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <RadioGroup
                    value={formValues.modelSaving}
                    onChange={(e) =>
                      handleInputChange("modelSaving", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="save-hf"
                      control={<Radio />}
                      label="Save safetensors to Hugging Face"
                    />
                    <FormControlLabel
                      value="convert-gguf-hf"
                      control={<Radio />}
                      label="Push model in GGUF Format to Hugging Face"
                    />
                    <FormControlLabel
                      value="convert-gguf-local"
                      control={<Radio />}
                      label="Download In GGUF Format"
                    />
                    <FormControlLabel
                      value="download"
                      control={<Radio />}
                      label="Download model + adapter"
                    />
                    <FormControlLabel
                      value="Download VLLM Implementation"
                      control={<Radio />}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Download VLLM Implementation
                          <span style={{
                            backgroundColor: 'rgb(219,234,254)',
                            color: 'rgb(29,78,216)',
                            padding: '2px 8px',
                            borderRadius: '12px',
                            fontSize: '0.75rem',
                            fontWeight: 'medium',
                            marginLeft: '8px'
                          }}>
                            Coming Soon
                          </span>
                        </Box>
                      }
                      disabled={true}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                  
                  {/* Add GuidanceBox */}
                  {getGuidanceMessage(formValues.step, formValues) && (
                    <GuidanceBox {...getGuidanceMessage(formValues.step, formValues)} />
                  )}

                  {(formValues.modelSaving === "save-hf" ||
                    formValues.modelSaving === "convert-gguf-hf") && (
                    <Box
                      sx={{
                        mt: 2,
                        ml: 4,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <TextField
                        label="Repository Name"
                        type="text"
                        value={formValues.hfRepoName}
                        onChange={(e) =>
                          handleInputChange("hfRepoName", e.target.value)
                        }
                        placeholder="Enter repository name"
                        fullWidth
                      />
                      <TextField
                        label="Hugging Face API Key"
                        type={formValues.showHfApiKey ? "text" : "password"}
                        value={formValues.hfApiKey}
                        onChange={(e) =>
                          handleInputChange("hfApiKey", e.target.value)
                        }
                        placeholder="Enter your Hugging Face API key"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleInputChange(
                                    "showHfApiKey",
                                    !formValues.showHfApiKey
                                  )
                                }
                                edge="end"
                              >
                                {formValues.showHfApiKey ? (
                                  <VisibilityOff size={20} />
                                ) : (
                                  <Visibility size={20} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}

{formValues.hasDocuments && ( // For CPT
                    <Box
                      sx={{ mt: 4, borderTop: 1, borderColor: "divider", pt: 4 }}
                    >
                      <Button
                        onClick={() =>
                          handleInputChange(
                            "showAdvancedParams",
                            !formValues.showAdvancedParams
                          )
                        }
                        endIcon={
                          formValues.showAdvancedParams ? (
                            <ChevronUp size={20} />
                          ) : (
                            <ChevronDown size={20} />
                          )
                        }
                        sx={{ mb: 2 }}
                      >
                        Advanced Parameters (PDF - Based Finetuning)
                      </Button>
                      {formValues.showAdvancedParams && (
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gap: 2,
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="model-label">
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                Model
                                <Tooltip title={params["model"]} arrow placement="top">
                                  <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                    <HelpCircle size={16} />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </InputLabel>

                            <Select
                              labelId="model-label"
                              value={formValues.advancedOptions.model}
                              onChange={(e) => handleAdvancedParamChange("model", e.target.value)}
                              label={<Box sx={{ display: "flex", alignItems: "center" }}>Model</Box>}
                            >
                              {[
                                { value: "unsloth/tinyllama-chat-bnb-4bit", label: "TinyLlama" },
                                // { value: "unsloth/gemma-2-2b-it-bnb-4bit", label: "Gemma 2.2B Instruct" },
                                { value: "unsloth/Phi-3.5-mini-instruct", label: "Phi 3.5 Mini Instruct" },
                                { value: "unsloth/SmolLM2-360M-Instruct-bnb-4bit", label: "SmolLM2 360M Instruct Bnb 4bit" },
                              ]
                                .filter((model) => {
                                  // If cpt is true AND either dataset field is "NULL", only show models with "Instruct"
                                  if (formValues.hasDocuments && (formValues.hfDatasetUrl === "NULL" || formValues.selectedDataset === "NULL")) {
                                    return model.label.includes("Instruct");
                                  }
                                  // Otherwise, show all models
                                  return true;
                                })
                                .map((model) => (
                                  <MenuItem key={model.value} value={model.value}>
                                    {model.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>

                          {/* New Fields */}


                          <TextField
                            label="PDF Num Train Epochs"
                            type="number"
                            value={formValues.advancedOptions_CPT.Epochs || 2}
                            onChange={(e) => {
                              let newValue = parseInt(e.target.value, 10);
                              if (isNaN(newValue) || newValue < 1) newValue = 1; // Ensure value is at least 1
                              handleAdvancedParamChange_CPT("Epochs", newValue);
                            }}
                            fullWidth
                            InputProps={{
                              inputProps: {
                                step: 1, // Step of 1
                                min: 1,  // Minimum value of 1
                              },
                            }}
                          />
                          <TextField
                            label="PDF Max Seq Length"
                            type="number"
                            value={formValues.advancedOptions_CPT["Max Sequence Length"] || 2048}
                            onChange={(e) => handleAdvancedParamChange_CPT("Max Sequence Length", e.target.value)}
                            fullWidth
                          />
                          <TextField
                            label="PDF Semantic Chunk Size"
                            type="number"
                            value={formValues.advancedOptions_CPT["Semantic Chunk Size"] || 500}
                            onChange={(e) => handleAdvancedParamChange_CPT("Semantic Chunk Size", e.target.value)}
                            fullWidth
                          />
                          <TextField
                            label="PDF Chunk Overlap"
                            type="number"
                            value={formValues.advancedOptions_CPT["Chunk Overlap"] || 50}
                            onChange={(e) => handleAdvancedParamChange_CPT("Chunk Overlap", e.target.value)}
                            fullWidth
                          />
                          <TextField
                            label="PDF Max Chunk Length"
                            type="number"
                            value={formValues.advancedOptions_CPT["Max Chunk Length"] || 512}
                            onChange={(e) => handleAdvancedParamChange_CPT("Max Chunk Length", e.target.value)}
                            fullWidth
                          />

                          <FormControl fullWidth>
                            <InputLabel id="pdf-lora-r-label">Lora Rank</InputLabel>
                            <Select
                              labelId="pdf-lora-r-label"
                              value={formValues.advancedOptions_CPT.Rank || 128}
                              onChange={(e) => handleAdvancedParamChange_CPT("Rank", e.target.value)}
                              label="PDF Lora R"
                              fullWidth
                            >
                              <MenuItem value={8}>8</MenuItem>
                              <MenuItem value={16}>16</MenuItem>
                              <MenuItem value={32}>32</MenuItem>
                              <MenuItem value={64}>64</MenuItem>
                              <MenuItem value={128}>128</MenuItem>
                            </Select>
                          </FormControl>
                          
                          <FormControl fullWidth>
                            <InputLabel id="pdf-lora-alpha-label">Lora Alpha</InputLabel>
                            <Select
                              labelId="pdf-lora-alpha-label"
                              value={formValues.advancedOptions_CPT["Lora Alpha"] || 0.25}
                              onChange={(e) => handleAdvancedParamChange_CPT("Lora Alpha", e.target.value  )}
                              label="Lora Alpha"
                              fullWidth
                            >
                              <MenuItem value={0.25}>0.25</MenuItem>
                              <MenuItem value={0.5}>0.5</MenuItem>
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              
                            </Select>
                          </FormControl>

                          <TextField
                            label="Weight Decay"
                            type="number"
                            value={formValues.advancedOptions_CPT["Weight Decay"] || 0.0}
                            onChange={(e) => {
                              let inputVal = e.target.value;
                              let newValue = inputVal === '' ? 0 : parseFloat(inputVal);
                              newValue = Math.max(0, Math.min(newValue, 0.1)); // Ensure value is between 0.0 and 0.1
                              handleAdvancedParamChange_CPT("Weight Decay", newValue);
                            }}
                            fullWidth
                            InputProps={{
                              inputProps: {
                                step: 0.001,
                                min: 0.0,
                                max: 0.1,
                              }
                            }}
                          />

                          <TextField
                            label="Embedding Learning Rate"
                            type="number"
                            value={formValues.advancedOptions_CPT["Embedding Learning Rate"] || 5e-6}
                            onChange={(e) => {
                              let inputVal = e.target.value;
                              let newValue = inputVal === '' ? 0 : parseFloat(inputVal);
                              newValue = Math.max(1e-6, Math.min(newValue, 1e-4)); // Ensure value is between 1e-6 and 1e-4
                              handleAdvancedParamChange_CPT("Embedding Learning Rate", newValue);
                            }}
                            fullWidth
                            InputProps={{
                              inputProps: {
                                step: 1e-6,
                                min: 1e-6,
                                max: 1e-4,
                              }
                            }}
                          />

                          <TextField
                            label="Logging Steps"
                            type="number"
                            value={formValues.advancedOptions_CPT["Logging Steps"] || 25} // Set default to 25
                            onChange={(e) => {
                              let newValue = parseInt(e.target.value, 10);
                              if (isNaN(newValue)) newValue = 25; // Default to 25 if input is not a number
                              newValue = Math.max(1, Math.min(newValue, 45)); // Ensure value is between 1 and 45
                              handleAdvancedParamChange_CPT("Logging Steps", newValue);
                            }}
                            fullWidth
                            InputProps={{
                              inputProps: {
                                step: 1, // Allow step of 1
                                min: 1,
                                max: 45,
                              },
                            }}
                          />
                          <FormControl fullWidth>
                            <InputLabel id="lr-scheduler-type-label">LR Scheduler Type</InputLabel>
                            <Select
                              labelId="lr-scheduler-type-label"
                              label="LR Scheduler Type"
                              value={formValues.advancedOptions_CPT["Learning Rate Scheduler Type"] || "cosine"}
                              onChange={(e) => handleAdvancedParamChange_CPT("Learning Rate Scheduler Type", e.target.value)}
                              fullWidth
                            >
                              <MenuItem value="cosine">Cosine</MenuItem>
                              <MenuItem value="linear">Linear</MenuItem>
                            </Select>
                          </FormControl>




                          <TextField
                            label="Optimizer"
                            value={formValues.advancedOptions_CPT.Optimizer || "adamw_8bit"}
                            onChange={(e) => handleAdvancedParamChange_CPT("Optimizer", e.target.value)}
                            fullWidth
                            InputProps={{
                              readOnly: true, // Make the field non-editable
                            }}
                            variant="filled" // Set variant to filled for grey background
                          />

                            <TextField
                            label="Learning Rate"
                            type="number"
                            value={formValues.advancedOptions_CPT["Learning Rate"] || 5e-5}
                            onChange={(e) => {
                              let inputVal = e.target.value;
                              let newValue = inputVal === '' ? 0 : parseFloat(inputVal);
                              newValue = Math.max(1e-5, Math.min(newValue, 1e-2)); // Ensure value is between 1e-5 and 1e-2
                              handleAdvancedParamChange_CPT("Learning Rate", newValue);
                            }}
                            fullWidth
                            InputProps={{
                              inputProps: {
                                step: 0.00001,
                                min: 1e-5,
                                max: 1e-2,
                              }
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                  <Box
                    sx={{ mt: 4, borderTop: 1, borderColor: "divider", pt: 4 }}
                  >
                    <Button
                      onClick={() =>
                        handleInputChange(
                          "showAdvancedParams",
                          !formValues.showAdvancedParams
                        )
                      }
                      endIcon={
                        formValues.showAdvancedParams ? (
                          <ChevronUp size={20} />
                        ) : (
                          <ChevronDown size={20} />
                        )
                      }
                      sx={{ mb: 2 }}
                    >
                      Advanced Parameters
                    </Button>
                    {formValues.showAdvancedParams && (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: 2,
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="model-label">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              Model
                              <Tooltip title={params["model"]} arrow placement="top">
                                <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                  <HelpCircle size={16} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </InputLabel>

                          <Select
                            labelId="model-label"
                            value={formValues.advancedOptions.model}
                            onChange={(e) => handleAdvancedParamChange("model", e.target.value)}
                            label={<Box sx={{ display: "flex", alignItems: "center" }}>Model</Box>}
                          >
                            {[
                              { value: "unsloth/tinyllama-chat-bnb-4bit", label: "TinyLlama" },
                              // { value: "unsloth/gemma-2-2b-it-bnb-4bit", label: "Gemma 2.2B Instruct" },
                              { value: "unsloth/Phi-3.5-mini-instruct", label: "Phi 3.5 Mini Instruct" },
                              { value: "unsloth/SmolLM2-360M-Instruct-bnb-4bit", label: "SmolLM2 360M Instruct Bnb 4bit" },
                            ]
                              .filter((model) => {
                                // If cpt is true AND either dataset field is "NULL", only show models with "Instruct"
                                if (formValues.hasDocuments && (formValues.hfDatasetUrl === "NULL" || formValues.selectedDataset === "NULL")) {
                                  return model.label.includes("Instruct");
                                }
                                // Otherwise, show all models
                                return true;
                              })
                              .map((model) => (
                                <MenuItem key={model.value} value={model.value}>
                                  {model.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        {Object.entries(formValues.advancedOptions).map(
                          ([key, value]) => {
                            // Skip rendering for 'model' and 'quantization_method' keys
                            if (key === "model" ) {
                              return null;
                            }

                            const isIntegerField = [
                              "Max Sequence Length",
                              "Generation Length",
                              "Lora Alpha",
                              "Rank",
                              "Logging Steps",
                            ].includes(key);

                            if (key === "Learning Rate Scheduler Type") {
                              return (
                                <FormControl fullWidth key={key}>
                                  <InputLabel id={`${key}-label`}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {key}
                                      <Tooltip title={params[key]} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </InputLabel>
                                  <Select
                                    labelId={`${key}-label`}
                                    value={value}
                                    onChange={(e) => handleAdvancedParamChange(key, e.target.value)}
                                    label={key}
                                  >
                                    <MenuItem value="linear">Linear</MenuItem>
                                    <MenuItem value="cosine">Cosine</MenuItem>
                                    <MenuItem value="polynomial">Polynomial</MenuItem>
                                  </Select>
                                </FormControl>
                              );
                            } else if (key === "Optimizer") {
                              return (
                                <TextField
                                  key={key}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {key}
                                      <Tooltip
                                        title={params[key]}
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          size="small"
                                          sx={{ ml: 1, p: 0 }}
                                        >
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  }
                                  value={value}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  variant="filled"
                                />
                              );
                            } 
                            else if (key === "Evaluation Split") {
                              if (formValues.finetuneType !== "Knowledge Injection") {
                                return (
                                  <TextField
                                    fullWidth
                                    key={key}
                                    label={
                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {key}
                                        <Tooltip title={params[key]} arrow placement="top">
                                          <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                            <HelpCircle size={16} />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    }
                                    type="number"
                                    value={value ? (value * 100).toFixed(1) : '0.5'} // Ensures default value is set to 0.5%
                                    onChange={(e) => {
                                      let inputVal = e.target.value;
                                      let newValue = parseFloat(inputVal);
                                      if (isNaN(newValue) || newValue < 0.5) newValue = 0.5; // Default to 0.5 if input is not a number or less than 0.5
                                      newValue = Math.min(newValue, 20); // Force value to be less than or equal to 20
                                      handleAdvancedParamChange(key, newValue / 100); // Convert back to a fraction for internal state management
                                    }}
                                    InputProps={{
                                      inputProps: {
                                        step: 0.1,
                                        min: 0.5,
                                        max: 20,
                                      }
                                    }}
                                    variant="outlined"
                                  />
                                );
                              }
                              else {
                                return null;
                              }
                            } else if (key === "Weight Decay") {
                              return (
                                <TextField
                                  key={key}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {key}
                                      <Tooltip
                                        title={params[key]}
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          size="small"
                                          sx={{ ml: 1, p: 0 }}
                                        >
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  }
                                  type="number"
                                  value={value}
                                  onChange={(e) => {
                                    let inputVal = e.target.value;
                                    let newValue = inputVal === '' ? 0 : parseFloat(inputVal);
                                    newValue = Math.max(0, Math.min(newValue, 0.1)); // Ensure value is between 0.0 and 0.1
                                    handleAdvancedParamChange(key, newValue);
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      step: 0.001,
                                      min: 0.0,
                                      max: 0.1,
                                    }
                                  }}
                                  variant="outlined"
                                />
                              );
                            } else if (key === "Learning Rate") {
                              return (
                                <TextField
                                  key={key}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {key}
                                      <Tooltip
                                        title={params[key]}
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          size="small"
                                          sx={{ ml: 1, p: 0 }}
                                        >
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  }
                                  type="number"
                                  value={value}
                                  onChange={(e) => {
                                    let inputVal = e.target.value;
                                    let newValue = inputVal === '' ? 0 : parseFloat(inputVal);
                                    newValue = Math.max(1e-5, Math.min(newValue, 1e-2)); // Ensure value is between 1e-5 and 1e-2
                                    handleAdvancedParamChange(key, newValue);
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      step: 0.00001,
                                      min: 1e-5,
                                      max: 1e-2,
                                    }
                                  }}
                                  variant="outlined"
                                />
                              );
                            }

                            else if (key === "Lora Alpha") {
                              return (
                                <FormControl fullWidth>
                                  <InputLabel id={`${key}-label`}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {key}
                                      <Tooltip title={params[key]} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </InputLabel>
                                  <Select
                                    labelId={`${key}-label`}
                                    value={value || 1} 
                                    
                                    onChange={(e) => handleAdvancedParamChange(key, e.target.value)}
                                    label={key}
                                  >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    
                                  </Select>
                                </FormControl>
                              );
                            }


                            else if (key === "Rank") {
                              return (
                                <FormControl fullWidth>
                                  <InputLabel id={`${key}-label`}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {key}
                                      <Tooltip title={params[key]} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </InputLabel>
                                  <Select
                                    labelId={`${key}-label`}
                                    value={value || 16} // Default value set to 16
                                    onChange={(e) => handleAdvancedParamChange(key, parseInt(e.target.value, 10))}
                                    label={key}
                                  >
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={16}>16</MenuItem>
                                    <MenuItem value={32}>32</MenuItem>
                                  </Select>
                                </FormControl>
                              );
                            }

                            else if (key === "Epochs") {
                              return (
                                <TextField
                                  fullWidth
                                  key={key}
                                  label={
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {key}
                                      <Tooltip title={params[key]} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  }
                                  type="number"
                                  value={value || '1'} // Ensures default value is set to 1
                                  onChange={(e) => {
                                    handleAdvancedParamChange(key, e.target.value)
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      step: 1,
                                      min: 1,
                                      max: 1000000000,
                                    }
                                  }}
                                  variant="outlined"
                                />
                              );

                            }



                            else if (key === "Quantization Method") {
                              return (

                                      <FormControl fullWidth>
        <InputLabel id="quantization-method-label">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            Quantization Method
            <Tooltip title={params["Quantization Method"]} arrow placement="top">
              <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                <HelpCircle size={16} />
              </IconButton>
            </Tooltip>
          </Box>
        </InputLabel>
        <Select
          labelId="quantization-method-label"
          value={formValues.advancedOptions?.["Quantization Method"] }
          onChange={(e) =>
            handleAdvancedParamChange("Quantization Method", e.target.value)
          }
          label="Quantization Method"
        >
          <MenuItem value="q4_0">q4_0</MenuItem>
          <MenuItem value="q8_0">q8_0</MenuItem>
          <MenuItem value="f16">f16</MenuItem>
        </Select>
      </FormControl>
                              )

                            }




                            return (
                              <TextField
                                key={key}
                                label={
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {key}
                                    <Tooltip
                                      title={params[key]}
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton
                                        size="small"
                                        sx={{ ml: 1, p: 0 }}
                                      >
                                        <HelpCircle size={16} />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                }
                                value={value}
                                onChange={(e) => {
                                  let newValue = e.target.value;

                                  if (isIntegerField) {
                                    newValue = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    if (newValue)
                                      newValue = parseInt(newValue, 10);
                                  }

                                  handleAdvancedParamChange(key, newValue);
                                }}
                                type={
                                  isIntegerField
                                    ? "number"
                                    : typeof value === "number"
                                      ? "number"
                                      : "text"
                                }
                                InputProps={{
                                  inputProps: {
                                    step: isIntegerField ? 1 : 0.1,
                                    min: 0,
                                  },
                                }}
                              />
                            );
                          }
                        )}


   
                      </Box>
                    )}
                  </Box>
                

                
                </Box>
              )}

              {step === 4 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <Typography variant="h6">Evaluation Management</Typography>
                  
                  {/* Add GuidanceBox */}
                  {getGuidanceMessage(formValues.step, formValues) && (
                    <GuidanceBox {...getGuidanceMessage(formValues.step, formValues)} />
                  )}

                  <FormControl fullWidth>
                    <InputLabel id="evaluation-dataset-type-label">
                      Evaluation Dataset
                    </InputLabel>
                    <Select
                      labelId="evaluation-dataset-type-label"
                      value={formValues.evaluationDatasetType}
                      onChange={(e) =>
                        handleInputChange(
                          "evaluationDatasetType",
                          e.target.value
                        )
                      }
                      label="Evaluation Dataset"
                    >
                      {/* <MenuItem value="huggingface" disabled={["Summary", "Text Gen", "Sentiment Analysis" ].includes(formValues.finetuneType) || (formValues.finetuneType === "Knowledge Injection" && (formValues.hfDatasetUrl === "NULL" || formValues.selectedDataset === "NULL"))}>
                        Generate Evaluation Dataset
                      </MenuItem> */}
                      <MenuItem
                        value="use-training"
                      >
                        Use Existing Data For Evaluation
                      </MenuItem>
                    </Select>
                  </FormControl>

                  {formValues.evaluationDatasetType === "huggingface" && (
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <TextField
                        label="HF Dataset Name"
                        type="url"
                        value={formValues.Eval_HF_URL}
                        onChange={(e) => {
                          handleInputChange("Eval_HF_URL", e.target.value);
                        }}
                        placeholder="Eval HF URL"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Upload PDFs or Word documents to HuggingFace, provide the name of the dataset and we'll generate an evaluation dataset for you">
                                <IconButton>
                                  <HelpCircle size={20} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Hugging Face API Key"
                        type={showHfApiKey ? "text" : "password"}
                        value={formValues.hfApiKey}
                        onChange={(e) =>
                          handleInputChange("hfApiKey", e.target.value)
                        }
                        placeholder="Enter your Hugging Face API key"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle API key visibility"
                                onClick={() => setShowHfApiKey(!showHfApiKey)}
                                edge="end"
                              >
                                {showHfApiKey ? (
                                  <EyeOff size={20} />
                                ) : (
                                  <Eye size={20} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="OpenAI API Key"
                        type={showApiKey ? "text" : "password"}
                        value={formValues.openAiApiKey}
                        onChange={(e) =>
                          handleInputChange("openAiApiKey", e.target.value)
                        }
                        placeholder="Enter your OpenAI API key"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle API key visibility"
                                onClick={() => setShowApiKey(!showApiKey)}
                                edge="end"
                              >
                                {showApiKey ? (
                                  <EyeOff size={20} />
                                ) : (
                                  <Eye size={20} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}

                  {formValues.evaluationDatasetType === "use-training" && (
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formValues.generateEvaluationDataset}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              handleInputChange("generateEvaluationDataset", checked);
                              handleMetricChange("aiEval") 
                              
                              
                              
                              if (checked) {
                                handleInputChange("Eval_HF_URL", formValues.hfDatasetUrl || formValues.selectedDataset);
                              } else {
                                handleInputChange("Eval_HF_URL", "");
                              }
                            }}
                            disabled={["Summary", "Text Gen", "Sentiment Analysis" ].includes(formValues.finetuneType)}
                          />
                        }
                        label="Enhance with Synthetic Data"
                      />
                      {formValues.generateEvaluationDataset && (
                        <TextField
                          label="OpenAI API Key"
                          type={showApiKey ? "text" : "password"}
                          value={formValues.openAiApiKey}
                          onChange={(e) =>
                            handleInputChange("openAiApiKey", e.target.value)
                          }
                          placeholder="Enter your OpenAI API key"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle API key visibility"
                                  onClick={() => setShowApiKey(!showApiKey)}
                                  edge="end"
                                >
                                  {showApiKey ? (
                                    <EyeOff size={20} />
                                  ) : (
                                    <Eye size={20} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Box>
                  )}



                  <Card variant="outlined" sx={{ mt: 3, p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Standard Evaluation Metrics
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.evaluationMetrics.loss}
                          onChange={() => handleMetricChange("loss")}
                          disabled
                        />
                      }
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Loss
                          <Tooltip title={EvalTooltips.loss} arrow>
                            <IconButton size="small">
                              <HelpCircle size={16} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      }
                    />
                  </Card>

                  {!["Text Gen", "Sentiment Analysis"].includes(formValues.finetuneType) && (
                    <Card variant="outlined" sx={{ mt: 3, p: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        AI-Eval Configuration
                      </Typography>
                      <FormControlLabel
                        
                        control={
                          <Switch
                            checked={formValues.evaluationMetrics.aiEval}
                            onChange={ (e) => { 
                              const checked = e.target.checked;
                              handleMetricChange("aiEval"  , checked)  
                              if (formValues.finetuneType !== "Summary"){
                                handleInputChange("generateEvaluationDataset", checked);
                              }
                              

                              
                            }}
                          />
                        }
                        label="Enable AI-Eval"
                      />

                      {formValues.evaluationMetrics.aiEval && (
                        <Box sx={{ mt: 2 }}>
                          <TextField
                            label="OpenAI API Key"
                            type={formValues.showOpenAiApiKey ? "text" : "password"}
                            value={formValues.openAiApiKey}
                            onChange={(e) => handleInputChange("openAiApiKey", e.target.value)}
                            placeholder="Enter your OpenAI API key"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle API key visibility"
                                    onClick={() =>
                                      handleInputChange(
                                        "showOpenAiApiKey",
                                        !formValues.showOpenAiApiKey
                                      )
                                    }
                                    edge="end"
                                  >
                                    {formValues.showOpenAiApiKey ? <EyeOff size={20} /> : <Eye size={20} />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Grid container spacing={2} sx={{ mt: 2 }}>
                            {Object.entries(formValues.aiEvalMetrics).map(([key, value]) => {
                              if (["QnA", "Knowledge Injection"].includes(formValues.finetuneType) && key === "summarisation") {
                                return null; // Do not render summarisation metric for QnA and Knowledge Injection
                              }
                              const isDisabled = formValues.finetuneType === "Summary" && key !== "summarisation";
                              return (
                                <Grid item xs={6} key={key}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={key === "summarisation" ? true : value} // Force Summarisation to be checked
                                        onChange={() => handleAiEvalMetricChange(key)}
                                        disabled={isDisabled}
                                      />
                                    }
                                    label={
                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {key === "geval" ? "G-Eval" : key.charAt(0).toUpperCase() + key.slice(1)}
                                        <Tooltip title={EvalTooltips[key]} arrow>
                                          <IconButton size="small">
                                            <HelpCircle size={16} />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    }
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      )}
                    </Card>
                  )}
                </Box>
              )}




              {step === 5 && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Form Submission Summary
                  </Typography>
                  
                  {/* Add GuidanceBox */}
                  {getGuidanceMessage(formValues.step, formValues) && (
                    <GuidanceBox {...getGuidanceMessage(formValues.step, formValues)} />
                  )}

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined" sx={{ height: "100%" }}>
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ fontWeight: "bold" }}
                          >
                            Fine-tuning Configuration
                          </Typography>
                          <List dense>
                            <ListItem>
                              <Typography variant="body2">
                                <strong>Type:</strong> {formValues.finetuneType}
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography variant="body2">
                                <strong>Data Source:</strong>{" "}
                                {formValues.dataSource}
                              </Typography>
                            </ListItem>
                            {formValues.selectedDataset && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>Selected Dataset:</strong>{" "}
                                  {formValues.selectedDataset}
                                </Typography>
                              </ListItem>
                            )}
                            {formValues.hfDatasetUrl && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>Hugging Face Dataset Name:</strong>{" "}
                                  {formValues.hfDatasetUrl}
                                </Typography>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined" sx={{ height: "100%" }}>
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ fontWeight: "bold" }}
                          >
                            Training Configuration
                          </Typography>
                          <List dense>
                            <ListItem>
                              <Typography variant="body2">
                                <strong>Training Location:</strong>{" "}
                                {formValues.trainingLocation}
                              </Typography>
                            </ListItem>
                            {formValues.trainingLocation === "local" && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>Optimize for Silicon:</strong>{" "}
                                  {formValues.optimizeForSilicon ? "Yes" : "No"}
                                </Typography>
                              </ListItem>
                            )}
                            <ListItem>
                              <Typography variant="body2">
                                <strong>Model Saving:</strong>{" "}
                                {formValues.modelSaving}
                              </Typography>
                            </ListItem>
                            {["save-hf", "convert-gguf-hf"].includes(
                              formValues.modelSaving
                            ) && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>HF Repository Name:</strong>{" "}
                                  {formValues.hfRepoName}
                                </Typography>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                    {Object.entries(formValues.advancedOptions).some(
                      ([key, value]) => value !== defaultAdvancedOptions[key]
                    ) && (
                      <Grid item xs={12}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{ fontWeight: "bold" }}
                            >
                              Advanced Parameters
                            </Typography>
                            <Grid container spacing={2}>
                              {Object.entries(formValues.advancedOptions).map(
                                ([key, value]) => {
                                  if (value !== defaultAdvancedOptions[key]) {
                                    return (
                                      <Grid item xs={6} key={key}>
                                        <Typography variant="body2">
                                          <Box
                                            component="span"
                                            sx={{ fontWeight: "bold" }}
                                          >
                                            {key}:
                                          </Box>{" "}
                                          {value}
                                        </Typography>
                                      </Grid>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ fontWeight: "bold" }}
                          >
                            Evaluation Configuration
                          </Typography>
                          <List dense>
                            {formValues.evaluationDatasetType === "huggingface" && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>Evaluation Dataset:</strong>{" "}
                                  {formValues.Eval_HF_URL}
                                </Typography>
                              </ListItem>
                            )}
                            {formValues.evaluationDatasetType === "use-training" && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>Evaluation Dataset:</strong>{" "}
                                  {formValues.Eval_HF_URL}
                                </Typography>
                              </ListItem>
                            )}
                            <ListItem>
                              <Typography variant="body2">
                                <strong>Standard Evaluation Metrics:</strong>{" "}
                                {Object.entries(formValues.evaluationMetrics)
                                  .filter(([key, value]) => value && key !== "aiEval")
                                  .map(([key]) => key)
                                  .join(", ")}
                              </Typography>
                            </ListItem>
                            {formValues.evaluationMetrics.aiEval && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>AI Evaluation Metrics:</strong>{" "}
                                  {Object.entries(formValues.aiEvalMetrics)
                                    .filter(([key, value]) => value)
                                    .map(([key]) => key)
                                    .join(", ")}
                                </Typography>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  <Box sx={{ mt: 4, mb: 2 }}>
                    <Card variant="outlined" sx={{ p: 2 }}>
                      <CardContent>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ fontWeight: "bold" }}
                        >
                          Name Your Creation 🚀
                        </Typography>
                        <TextField
                          fullWidth
                          value={formValues.creationName}
                          onChange={(e) =>
                            handleInputChange("creationName", e.target.value)
                          }
                          variant="outlined"
                          size="small"
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "100%",
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
              )}
            </CardContent>
            <CardActions
              sx={{ justifyContent: "space-between", p: 3, display: "flex" }}
            >
              <Button
                variant="outlined"
                onClick={() => handleInputChange("step", formValues.step - 1)}
                disabled={formValues.step === 0}
                startIcon={<ChevronLeft size={20} />}
                sx={{
                  borderRadius: "8px",
                  textTransform: "uppercase",
                  fontWeight: 500,
                  color: "rgb(100, 116, 139)",
                  borderColor: "rgb(226, 232, 240)",
                  "&:hover": {
                    borderColor: "rgb(203, 213, 225)",
                  },
                }}
              >
                Previous
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  if (formValues.step === 5) {
                    submitFormHandler(formValues);
                    navigate("/finetuning-dashboard");
                    
                  } else {
                    handleNextStep();
                  }
                }}
                endIcon={
                  formValues.step === 5 ? <ChevronRight size={20} /> : null
                }
                sx={{
                  borderRadius: "8px",
                  textTransform: "uppercase",
                  fontWeight: 500,
                  bgcolor: formValues.step === 5 ? "#22c55e" : "rgb(59,89,238)",
                  "&:hover": {
                    bgcolor:
                      formValues.step === 5 ? "#16a34a" : "rgb(48,73,191)",
                  },
                }}
              >
                {formValues.step === 5 ? "Confirm Submission" : "Next"}
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Box>
    </FormProvider>
  );
}
