import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { ChevronRight, X } from 'lucide-react';

const GuidanceBox = ({ message, action, show = true }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isVisible, setIsVisible] = useState(true);

  if (!show || !isVisible) return null;

  return (
    <Box 
      sx={{ 
        position: 'absolute',
        top: '80px',
        ...(isMobile ? {
          right: '20px',
        } : {
          right: '180px',
        }),
        width: {
          xs: '250px',
          sm: '300px'
        },
        bgcolor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
        p: 2,
        border: '1px solid rgb(219,234,254)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        zIndex: 1000,
        animation: 'fadeIn 0.3s ease-in-out',
        '@keyframes fadeIn': {
          '0%': {
            opacity: 0,
            transform: 'translateY(10px)'
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)'
          }
        }
      }}
    >
      {/* Close button */}
      <IconButton
        onClick={() => setIsVisible(false)}
        sx={{
          position: 'absolute',
          right: 4,
          top: 4,
          padding: '4px',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <X size={16} />
      </IconButton>

      <Typography variant="body2" sx={{ fontWeight: 'medium', color: 'rgb(30,58,138)', pr: 4 }}>
        {message}
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        color: 'rgb(59,89,238)'
      }}>
        <ChevronRight size={20} />
        <Typography variant="body2">
          {action}
        </Typography>
      </Box>
    </Box>
  );
};

export const getGuidanceMessage = (step, formValues) => {
  // Model Saving step
  if (step === 3) {
    if (formValues.showAdvancedParams) {
      return {
        message: "Oh, we've got a pro here! 🚀 Tweaking the advanced settings for optimal performance.",
        action: "Adjust the parameters to your needs"
      };
    }
    
    if (!formValues.modelSaving) {
      return {
        message: "Choose how you'd like to save your fine-tuned model.",
        action: "Select a saving option to proceed"
      };
    }

    if (["save-hf", "convert-gguf-hf"].includes(formValues.modelSaving) && (!formValues.hfRepoName || !formValues.hfApiKey)) {
      return {
        message: "Please provide your Hugging Face repository details.",
        action: "Fill in the repository information"
      };
    }

    return {
      message: "Great! Your model saving preferences are set.",
      action: "Click Next to proceed"
    };
  }

  // Evaluation Management step
  if (step === 4) {
    if (!formValues.evaluationDatasetType) {
      return {
        message: "Let's set up how we'll evaluate your model's performance.",
        action: "Choose an evaluation dataset type"
      };
    }

    if (formValues.evaluationMetrics.aiEval && !formValues.openAiApiKey) {
      return {
        message: "To use AI evaluation, we'll need your OpenAI API key.",
        action: "Enter your OpenAI API key"
      };
    }

    if (formValues.evaluationDatasetType === "huggingface" && (!formValues.Eval_HF_URL || !formValues.hfApiKey)) {
      return {
        message: "Please provide your evaluation dataset details.",
        action: "Fill in the dataset information"
      };
    }

    return {
      message: "Perfect! Your evaluation setup is complete.",
      action: "Click Next to proceed"
    };
  }

  // Summary step
  if (step === 5) {
    if (!formValues.creationName) {
      return {
        message: "Almost there! Give your fine-tuning creation a memorable name. 🌟",
        action: "Enter a name for your creation"
      };
    }

    return {
      message: "Everything looks great! Ready to start fine-tuning your model.",
      action: "Click Confirm Submission to begin"
    };
  }

  return null;
};

export default GuidanceBox; 